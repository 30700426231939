import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { StaticImage } from 'gatsby-plugin-image';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    closeIcon: {
      position: 'fixed',
      top: '10px',
      right: '10px',
      zIndex: 10
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  })
);

const DashboardImagePreviewMobile: React.FC<any> = ({ onDialogClose, open }) => {
  const classes = useStyles();
  return (
    <Dialog fullScreen maxWidth='xs' fullWidth onClose={onDialogClose} aria-labelledby='simple-dialog-title' open={open}>
      <div className={classes.closeIcon}>
        <IconButton aria-label='close' onClick={onDialogClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div data-testid='zoomable-div'>
          <StaticImage layout='constrained' placeholder='tracedSVG' src='../../assets/DesktopGraphic.png' alt='Novade Lite logo' />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DashboardImagePreviewMobile;
