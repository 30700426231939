import { createStyles, makeStyles } from '@material-ui/core/styles';

const useResultCardStyles = makeStyles(() =>
  createStyles({
    templateCard: {
      cursor: 'pointer',
      '& div': {
        padding: '1rem'
      }
    },
    templateTitle: {
      fontSize: '1rem',
      fontWeight: 500,
      float: 'left'
    },
    templateDate: {
      float: 'right',
      fontSize: '0.875rem',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.6)'
    }
  })
);

export default useResultCardStyles;
