import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { StaticImage } from 'gatsby-plugin-image';
import IconButton from '@material-ui/core/IconButton';
import ZoomInOutlined from '@material-ui/icons/ZoomInOutlined';
import ZoomOutOutlined from '@material-ui/icons/ZoomOutOutlined';
import useImagePreviewStyle from '../../styles/ImagePreviewDialog';

interface ImagePreviewDialogProps {
  img: string;
  open: boolean;
  onDialogClose: () => void;
}

const ImagePreviewDialog: React.FC<ImagePreviewDialogProps> = ({ img, open, onDialogClose }) => {
  const classes = useImagePreviewStyle();
  const [zoom, setZoom] = React.useState(1); // Initial zoom level is 1 (100%)

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Max zoom level is 2 (200%)
  const handleZoomOut = () => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Min zoom level is 0.5 (50%)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onDialogClose}
      style={{ margin: '0 10rem 0 10rem' }}
      BackdropProps={{ classes: { root: classes.dialogBackdrop } }}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
    >
      <div className={classes.closeIcon}>
        <IconButton aria-label='close' onClick={onDialogClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container className={classes.dialogContent}>
        <Grid item xs={12}>
          <div style={{ transform: `scale(${zoom})` }} className={classes.img} data-testid='zoomable-div'>
            <StaticImage layout='constrained' placeholder='blurred' src='../../assets/DesktopGraphic.png' alt='DesktopGraphic' />
          </div>
          <div className={classes.zoomDiv}>
            <IconButton onClick={handleZoomIn} aria-label='zoom-in'>
              <ZoomInOutlined />
            </IconButton>
            <IconButton onClick={handleZoomOut} aria-label='zoom-out'>
              <ZoomOutOutlined />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ImagePreviewDialog;
