import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import SwapHorizOutlined from '@material-ui/icons/SwapHorizOutlined';
import { DashboardActionButtonsProps } from 'typings';
import useDashboardStyles from '../../styles/Dashboard';

const DashboardActionButtonMobile: React.FC<DashboardActionButtonsProps> = ({ dashboardPageContent, onSaveToNL, loading }) => {
  const classes = useDashboardStyles();

  return (
    <AppBar position='sticky' color='primary' className={classes.mobileFooter}>
      <Toolbar className={classes.mobileFooterToolbar}>
        <Button onClick={onSaveToNL} disabled={loading}>
          <SwapHorizOutlined fontSize='small' /> {dashboardPageContent.actionButton}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardActionButtonMobile;
