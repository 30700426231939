import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { LocationLanguageContextType, StrapiTemplateSearchResult } from '../typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { Languages } from '../utils/getAllSupportedLanguages';
import useResultCardStyles from '../styles/ResultCard';

interface StrapiSearchResult {
  CreatedDateLabel: string;
  locale: string;
}

interface SearchResultDateQueryProps {
  allStrapiSearchResult: {
    nodes: StrapiSearchResult[];
  };
}

interface ResultCardProps {
  resultDetails: StrapiTemplateSearchResult;
}

const ResultCard: React.FC<ResultCardProps> = ({ resultDetails }) => {
  const classes = useResultCardStyles();
  return (
    <StaticQuery
      query={graphql`
        query SearchResultDateQuery {
          allStrapiSearchResult {
            nodes {
              CreatedDateLabel
              locale
            }
          }
        }
      `}
      render={(data: SearchResultDateQueryProps) => {
        const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
        const language: string = locationLanguage?.language ?? Languages.English;
        const searchResultContent = data.allStrapiSearchResult.nodes.find((x: any) => x.locale === language);

        const handleTemplateOpen = () => {
          navigate(`/template/${resultDetails.Slug}`);
        };

        if (searchResultContent) {
          return (
            <Paper className={classes.templateCard} onClick={handleTemplateOpen}>
              <div>
                <Typography variant='h2' style={{ paddingBottom: '1rem' }}>
                  <span className={classes.templateTitle}>{resultDetails.Title}</span>
                  <span className={classes.templateDate}>
                    <small>
                      {searchResultContent.CreatedDateLabel} {new Date(resultDetails.published_at).toLocaleDateString()}
                    </small>
                  </span>
                </Typography>
                <Typography variant='body2' noWrap style={{ margin: '1rem 0' }}>
                  {resultDetails.Description}
                </Typography>
                <Typography variant='body2' color='primary'>
                  {resultDetails.Author}
                </Typography>
              </div>
            </Paper>
          );
        }
        return null;
      }}
    />
  );
};
export default ResultCard;
