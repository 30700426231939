import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link, StaticQuery, graphql } from 'gatsby';
import { StrapiTemplateSearchResult, LocationLanguageContextType, TemplateCategory } from '../typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { Languages } from '../utils/getAllSupportedLanguages';

interface StrapiSearchResult {
  CreatedDateLabel: string;
  locale: string;
}

interface SearchResultDateQueryProps {
  allStrapiSearchResult: {
    nodes: StrapiSearchResult[];
  };
}

interface ResultCardProps {
  resultDetails: StrapiTemplateSearchResult;
}

const ResultCardMobile: React.FC<ResultCardProps> = ({ resultDetails }) => (
  <StaticQuery
    query={graphql`
      query SearchResultMobileDateQuery {
        allStrapiSearchResult {
          nodes {
            CreatedDateLabel
            locale
          }
        }
      }
    `}
    render={(data: SearchResultDateQueryProps) => {
      const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
      const language: string = locationLanguage?.language ?? Languages.English;

      const searchResultContent = data.allStrapiSearchResult.nodes.find((x: any) => x.locale === language);
      if (searchResultContent) {
        return (
          <Link to={`/template/${resultDetails.Slug}`} style={{ textDecoration: 'none' }}>
            <Paper style={{ padding: '1rem' }}>
              <Typography variant='subtitle1'>{resultDetails.Title}</Typography>
              {resultDetails.Description ? (
                <Typography variant='body2' display='block' noWrap style={{ width: '15rem', margin: '1rem 0' }}>
                  {resultDetails.Description}
                </Typography>
              ) : null}
              <Typography variant='body2' color='primary'>
                {resultDetails.Author}
              </Typography>
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='caption'>
                  {searchResultContent.CreatedDateLabel} {new Date(resultDetails.published_at).toLocaleDateString()}
                </Typography>
              </div>
            </Paper>
          </Link>
        );
      }
      return null;
    }}
  />
);
export default ResultCardMobile;
