import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StrapiDashboardPage, StrapiTemplate } from 'typings';
import ResultCard from '../ResultCard';
import ResultCardMobile from '../ResultCardMobile';
import useDashboardStyles from '../../styles/Dashboard';

interface LinkedTemplatesProps {
  dashboardPageContent: StrapiDashboardPage;
  templates: StrapiTemplate[];
  isMobile: boolean;
}

const LinkedTemplates: React.FC<LinkedTemplatesProps> = ({ dashboardPageContent, templates, isMobile }) => {
  const classes = useDashboardStyles();
  const alignment = isMobile ? 'center' : 'left';

  return (
    <Grid container direction='row' spacing={1} style={{ marginTop: '1rem' }}>
      <Grid item xs={12}>
        <Typography variant='h2' align={alignment} className={`${classes.marginBottom} ${classes.templateHeaderTwo}`}>
          {dashboardPageContent.text}
        </Typography>
        <Typography variant='body1' align={alignment} className={`${classes.marginBottom} ${classes.templateBodyText}`}>
          {dashboardPageContent.longText}
        </Typography>
        <div className={classes.searchResultContainer}>
          {templates.map((template) =>
            isMobile ? (
              <ResultCardMobile resultDetails={template} key={template.Slug} />
            ) : (
              <ResultCard resultDetails={template} key={template.Slug} />
            )
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LinkedTemplates;
