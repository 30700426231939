import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StrapiDashboard, StrapiDashboardPage } from 'typings';
import useDashboardStyles from '../../styles/Dashboard';

interface DashboardInfoSectionProps {
  dashboardPageContent: StrapiDashboardPage;
  strapiDashboard: StrapiDashboard;
  isMobile: boolean;
}

const DashboardInfoSection: React.FC<DashboardInfoSectionProps> = ({ strapiDashboard, dashboardPageContent, isMobile }) => {
  const classes = useDashboardStyles();
  const alignment = isMobile ? 'center' : 'left';
  const { title, author, published_at } = strapiDashboard; // eslint-disable-line @typescript-eslint/naming-convention
  const { authorLabel, publishDateLabel } = dashboardPageContent;

  return (
    <Grid item xs={12} md={9}>
      <Typography variant='h1' align={alignment} className={`${classes.marginBottom} ${classes.templateHeaderOne}`}>
        {title}
      </Typography>
      <Typography
        variant='body1'
        className={`${classes.marginBottom} ${classes.templateBodyText}`}
        style={{ color: 'rgba(40, 100, 172, 1)' }}
        align={alignment}
      >
        {authorLabel} {author}
      </Typography>
      <Typography variant='body1' align={alignment} className={`${classes.marginBottom} ${classes.templateBodyText}`}>
        {publishDateLabel} {new Date(published_at).toLocaleDateString()}
      </Typography>
    </Grid>
  );
};

export default DashboardInfoSection;
