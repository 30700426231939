import { Dashboard, NovadeLiteDashboardInput, WidgetSourceMap, WidgetTypeMap, WidgetSetTypeMap } from 'typings';

const dashboardWidgetSetType: WidgetSetTypeMap = {
  projectName: 'ProjectName',
  projectCode: 'ProjectCode',
  formID: 'FormID',
  templateName: 'TemplateName',
  templateCode: 'TemplateCode',
  templateVersion: 'TemplateVersion',
  creator: 'Creator',
  updatedAt: 'UpdatedAt',
  updatedBy: 'UpdatedBy',
  score: 'Score',
  tags: 'Tags',
  currentStatus: 'CurrentStatus',
  currentStatusDate: 'CurrentStatusDate',
  currentStatusBy: 'CurrentStatusBy',
  currentStatusLocation: 'CurrentStatusLocation',
  currentStatusExplanation: 'CurrentStatusExplanation',
  field: 'Field',
  workflowStep: 'WorkflowStep',
  validity: 'Validity',
  overdue: 'Overdue',
  active: 'Active',
  expired: 'Expired',
  expiringIn1h: 'ExpiringIn1Hour',
  expiringIn1d: 'ExpiringIn1Day',
  expiringIn1w: 'ExpiringIn1Week',
  expiringIn1m: 'ExpiringIn1Month',
  totalCount: 'TotalCount'
};

const dashboardWidgetSourceMap: WidgetSourceMap = { form: 'Form' };

const dashboardWidgetTypeMap: WidgetTypeMap = {
  table: 'Table',
  tableWithCount: 'TableWithCount',
  indicator: 'Indicator'
};

export const getDashboardPortalInput = (dashboard: Dashboard): NovadeLiteDashboardInput => {
  const widgets = dashboard.widgets.map((widget) => {
    const { source, type, sets } = widget;
    return {
      ...widget,
      type: dashboardWidgetTypeMap[type],
      source: dashboardWidgetSourceMap[source],
      sets: sets.map((widgetSet) => ({ ...widgetSet, type: dashboardWidgetSetType[widgetSet.type] }))
    };
  });
  return { ...dashboard, widgets };
};
