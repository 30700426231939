import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from './theme';

const useDashboardStyles = makeStyles(() =>
  createStyles({
    buttonList: {
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    buttonCircle: {
      padding: '0.5rem',
      background: '#fff',
      borderRadius: '16px',
      width: '50px',
      height: '50px',
      letterSpacing: '-1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    listItemText: {
      textAlign: 'center',
      padding: '0.5rem 0',
      color: '#2864ac',
      fontSize: '0.7rem'
    },
    marginBottom: {
      marginBottom: '0.5rem'
    },
    templateHeaderOne: {
      fontSize: '1.25rem',
      fontWeight: 600
    },
    templateBodyText: {
      fontSize: '0.8rem'
    },
    templateHeaderTwo: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.43
    },
    searchResultContainer: {
      display: 'grid',
      gap: '1rem',
      marginTop: '1rem',
      gridTemplateColumns: '100%'
    },
    actionButtons: {
      position: 'sticky',
      top: '5.5rem'
    },
    actionButtonItem: {
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      imagePreview: {
        order: 2
      },
      infoSection: {
        order: 1
      },
      mobileFooter: {
        bottom: 0,
        top: 'auto',
        backgroundColor: '#f8f8f8'
      },
      mobileFooterToolbar: {
        display: 'grid',
        '& button': {
          color: 'rgba(40, 100, 172, 1)',
          fontSize: '0.8rem'
        }
      }
    }
  })
);

export default useDashboardStyles;
