import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Database, StrapiDashboard, NovadeLiteTemplateInput, NovadeLitePackageInput } from 'typings';
import { setGTMEvent } from '../utils/setGAEvent';
import { getSortedTemplate } from '../utils/templateValues';
import { getDashboardPortalInput } from '../utils/dashboard';

const UPSERT_DASHBOARD_DATA = gql`
  mutation upsertDashboardPackage($package: TemplatePortalPackageInput!) {
    savePortalPackageToWorkspace(package: $package) {
      success
      error
    }
  }
`;

const getDashboardPackage = (strapiDashboard: StrapiDashboard): NovadeLitePackageInput => {
  const { dashboard, templates } = strapiDashboard;
  const linkedTemplates: NovadeLiteTemplateInput[] = templates.map((template) =>
    getSortedTemplate({ ...template.Template, ...{ id: template.id } })
  );
  return {
    dashboard: getDashboardPortalInput(dashboard),
    templates: linkedTemplates
  };
};

const useUpsertDashboard = (
  endpoint: string,
  targetWorkspace: Database | undefined,
  strapiDashboard: StrapiDashboard,
  afterUpsertCallback: (arg0: boolean) => void
) => {
  const dashboardPackage = getDashboardPackage(strapiDashboard);
  const [upsertDashboardPackage, { loading, error }] = useMutation<{ savePackageToWorkspace: any }, { package: NovadeLitePackageInput }>(
    UPSERT_DASHBOARD_DATA,
    {
      variables: {
        package: dashboardPackage
      },
      context: {
        uri: `${endpoint}/graphql`,
        headers: {
          Authorization: targetWorkspace ? `Bearer ${targetWorkspace.token}` : ''
        }
      },
      onCompleted(success) {
        if (success) {
          setGTMEvent('Save dashboard to Novade Lite workspace', 'save_To_Novade_Lite', dashboardPackage.dashboard.name);
          afterUpsertCallback(true);
        }
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors || networkError) {
          afterUpsertCallback(false);
        }
      }
    }
  );

  const upsertDashboard = React.useCallback(() => {
    upsertDashboardPackage();
  }, [upsertDashboardPackage]);

  return { upsertDashboard, loading, error };
};

export default useUpsertDashboard;
