import React from 'react';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import { StaticImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { DashboardActionButtonsProps } from 'typings';
import useDashboardStyles from '../../styles/Dashboard';

const DashboardActionButtonsWeb: React.FC<DashboardActionButtonsProps> = ({ dashboardPageContent, onSaveToNL, loading }) => {
  const classes = useDashboardStyles();

  return (
    <div className={classes.actionButtons}>
      <List className={classes.actionButtonItem}>
        <ListItem button onClick={onSaveToNL} className={classes.buttonList} disabled={loading}>
          <ListItemIcon>
            <div className={classes.buttonCircle} style={{ backgroundColor: '#2b5b94' }}>
              <StaticImage
                width={28}
                layout='constrained'
                placeholder='tracedSVG'
                src='../../assets/TemplatePreview/sync_alt_white.svg'
                alt='Save to Novade Lite'
              />
            </div>
          </ListItemIcon>
          <Typography variant='body2' className={classes.listItemText}>
            {dashboardPageContent.actionButton}
          </Typography>
        </ListItem>
      </List>
    </div>
  );
};

export default DashboardActionButtonsWeb;
