import { makeStyles, createStyles } from '@material-ui/core';

const useImagePreviewStyle = makeStyles(() =>
  createStyles({
    dialogBackdrop: {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.85)'
    },
    dialogPaper: {
      top: '10px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        width: '0px',
        background: 'transparent'
      }
    },
    dialogContent: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      margin: 0,
      overflow: 'hidden'
    },
    closeIcon: {
      position: 'fixed',
      top: '10px',
      right: '10px'
    },
    closeButton: {
      backgroundColor: '#fff',
      width: '1.5em',
      height: '1.5em',
      '&:hover': {
        backgroundColor: '#f4f4f4'
      }
    },
    img: {
      transformOrigin: 'center center',
      transition: 'transform 0.3s',
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    zoomDiv: {
      position: 'fixed',
      bottom: '10px',
      left: '0',
      right: '0',
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      '& button': {
        color: '#fff'
      }
    }
  })
);

export default useImagePreviewStyle;
